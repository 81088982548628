import { Component, OnInit, Inject, DestroyRef, inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthGuardService } from '../../services/auth-guard.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface Items {
    name: string;
    checked: boolean;
    withoutcheckbox?: boolean;
    id: string;
    description: string;
    privacy: string;
}

interface Data {
    components: Items[]
}
@Component({
    selector: 'app-cookie',
    styleUrls: ['./cookie.component.scss'],
    templateUrl: './cookie.component.html',
    standalone: false
})
export class CookieComponent implements OnInit {
    openConfigs: boolean = false;
    mobile: boolean = false;
    components: Items[] = [];
    destroyRef = inject(DestroyRef);
    _navigator = navigator;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private auth: AuthGuardService,
        protected mediaObserver: BreakpointObserver,
        protected cdr: ChangeDetectorRef,
        public snackBarRef: MatDialogRef<CookieComponent>
    ) {}

    ngOnInit(): void {
        this.components = this.data?.components;
        this.initMedia();
    }

    initMedia(): void {
        if (this.mediaObserver.isMatched(Breakpoints.XSmall)) {
            this.mobile = true;
        }
        if (this.mediaObserver.isMatched(Breakpoints.Small)) {
            this.mobile = true;
        }
        if (this.mediaObserver.isMatched(Breakpoints.Medium)) {
            this.mobile = false;
        }
        if (this.mediaObserver.isMatched(Breakpoints.Large)) {
            this.mobile = false;
        }
        if (this.mediaObserver.isMatched(Breakpoints.XLarge)) {
            this.mobile = false;
        }
        this.cdr.detectChanges();
    }

    async openDatenschutz() {
        window.open('https://' + this.auth.getPartner()?.partnerdomain + '/datenschutz', '_blank');
    }

    async openImpressum() {
        window.open('https://' + this.auth.getPartner()?.partnerdomain + '/impressum', '_blank');
    }

    save(): void {
        this.snackBarRef.close(this.components);
    }

    reject(): void {
        for (let i = 0; i < this.components.length; i++) {
            if (this.components[i].id?.length > 0) {
                this.components[i].checked = false;
            }
        }
        this.snackBarRef.close(this.components);
    }

    allaccept(): void {
        for (let i = 0; i < this.components.length; i++) {
            if (this.components[i].id?.length > 0) {
                this.components[i].checked = true;
            }
        }
        this.snackBarRef.close(this.components);
    }
}
